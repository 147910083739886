import React from 'react';


const Section = ({ children, className, innerClassName = '', id }) => {
    return (
        <section id={id} className={`${className} w-full flex justify-center even:bg-gray-200`}>
            <div className={`max-w-4xl px-4 py-12 ${innerClassName}`}>
                {children}
            </div>
        </section>
    );
}

export default Section;