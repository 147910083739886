import React from "react";
import PropTypes from "prop-types";
import ProjectPage from "modules/project/components/project-page";

const ProjectPageWrapper = ({ pageContext }) => {
  const {
    html,
    description,
    title,
    projectDate,
    projectUrl,
    featuredChildImageSharp,
  } = pageContext;
  return (
    <ProjectPage
      html={html}
      description={description}
      projectDate={projectDate}
      projectUrl={projectUrl}
      title={title}
      featuredChildImageSharp={featuredChildImageSharp}
    />
  );
};

ProjectPageWrapper.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ProjectPageWrapper;
