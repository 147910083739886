import React from "react";
import { Link } from "gatsby";
import { HTMLContent } from "../../common/components/html-content";
// import PreviewCompatibleImage from "../../common/components/preview-compatible-image";
import Layout from "../../common/components/layout";
import Section from "../../common/components/section";
import { DISPLAY_NAMES } from "../../common/api/constants";
import SEO from "../../common/components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProjectPage = ({
  html,
  title,
  projectdate,
  projectUrl,
  featuredChildImageSharp
}) => {
  const imageData = getImage(featuredChildImageSharp)
  return (
    <Layout>
      <SEO
        keywords={['software project', title]}
        title={title}
      />
      <Section className={'mb-8'}>
        <Link to={`/software`}>
          <h5 className="m-0 py-1 px-1.5 text-sm rounded-sm mb-4 bg-red-800 hover:bg-red-700 text-gray-100 inline-block uppercase">{DISPLAY_NAMES.DEV}</h5>
        </Link>
        <h1 className="text-2xl sm:text-3xl font-semibold sm:font-medium mb-6">{title}</h1>
        {projectdate && <p className="text-gray-500 font-sans mb-8 uppercase">
          Project date: {projectdate}
        </p>}
        <GatsbyImage className="mb-8 w-full" image={imageData} alt={`primary image for ${title}`} />
        <HTMLContent content={html} className={'markdown'} />
        {projectUrl && <div className="mt-8"> <a className="uppercase font-semibold text-red-800 rounded-md border-2 hover:bg-red-800 hover:text-gray-50 border-red-800 px-4 py-2" target="_blank" rel="noreferrer" href={projectUrl}>View project</a></div>}
      </Section>
    </Layout>
  );
};

export default ProjectPage;
